<template>
    <OColumnGroup :groupId="column.groupId" :key="column.groupId" :parentGroupId="parentId" :headerName="column.groupId"  :children="column.children">
        <template v-for="col in childrenToSend">
            <TableLayoutColumnGroup v-if="col.groupId" :column="col" :parentId="column.groupId" />
            <OColumn v-else-if="col.field" :colId="col.field" :type="getColumnType(col.type)" :format="col.format"
                :headerName="col.headerName ? col.headerName : col.field" :summaryAggregate="col.aggregateType"
                />
            <OColumn v-else colId="NoColumnsFound" :headerName="$t('No Columns Found')"></OColumn>

        </template>
    </OColumnGroup>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeMount } from "vue";

const props = defineProps({
    column: {
        type: Object,
        required: true,
        default: []
    },
    parentId: {
        default: null
    }
})
const childrenToSend = ref(null);

function getColumnType(type:string){
    if(type == "Number"){
        return "number"
    }else if(type == "Date"){
        return "date"
    }else{
        return "string"
    }
}

onBeforeMount(() => {
    childrenToSend.value = props.column.children;
});
</script>